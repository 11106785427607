import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const eBooks = [
  {
    title: "Nasıl Frontend Uzmanı Olurum",
    url:
      "https://learnreactui.dev/contents/Nas%C4%B1l%20Frontend%20Uzman%C4%B1%20Olurum%3F",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Ff5150d60-df6c-497d-823d-28e79117da4b%2Fcovers%2FNFUO_Cover2.png",
  },
  {
    title: "How to Became Frontend Master",
    url:
      "https://learnreactui.dev/contents/How%20To%20Became%20Frontend%20Master",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F8126d688-c5b7-407c-9957-dda8289c7c80%2Fcovers%2FHBFM_Cover5.png",
  },
  {
    title: "Logical Model and Concepts for React Applications",
    url:
      "https://learnreactui.dev/contents/logical-model-and-concepts-for-react-apps",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F6743d9b6-654f-4496-b6f8-eedc4c2ef9c9%2Fcovers%2FLCMFR_Cover3.png",
  },
  {
    title: "Teknolojik Değişim ve Baskının Üstesinden Gelmek",
    url:
      "https://learnreactui.dev/contents/teknolojik-degisim-ve-baskinin-ustesinden-gelmek",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F589c870e-d5cb-4ff0-8c88-67703f865c19%2Fcovers%2FTDBUG_Cover2.png",
  },
  {
    title: "Overcoming Technological Change and Pressure",
    url:
      "https://learnreactui.dev/contents/overcome-technological-change-and-pressure",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fee5b7b98-cb1d-4bb7-99a1-8e84b912fd26%2Fcovers%2FOTCP_Cover2.png",
  },
]

const LinkMap = {
  "DOM Bilgisi": "baf51b8e256d",
  "Web Animations API": "baf51b8e256d",
  Math: "baf51b8e256d",
  Fetch: "baf51b8e256d",
  HTTPRequest: "baf51b8e256d",
  AJAX: "baf51b8e256d",
  JSON: "baf51b8e256d",
  "JS Structures": "baf51b8e256d",
  LocalStorage: "baf51b8e256d",
  "JS Engine": "baf51b8e256d",
  "JS Ecmascript": "baf51b8e256d",
  "Browser Engine": "baf51b8e256d",
  Preprocessor: "baf51b8e256d",
  "Single Page App": "baf51b8e256d",
  "Static Site Generation": "baf51b8e256d",
  "Progress Web App": "baf51b8e256d",
  "CSS-in-JS": "baf51b8e256d",
  WebAPI: "baf51b8e256d",
  BrowserAPI: "baf51b8e256d",
  "DOM API": "baf51b8e256d",
  SVG: "baf51b8e256d",
  Canvas: "baf51b8e256d",
  History: "baf51b8e256d",
  URL: "baf51b8e256d",
  Console: "baf51b8e256d",
  "Drag Drop": "baf51b8e256d",
  WebGL: "baf51b8e256d",
  WebAudio: "baf51b8e256d",
  WebSpeech: "baf51b8e256d",
  "Device API": "baf51b8e256d",
  "Communication API": "baf51b8e256d",
  "Data Management API": "baf51b8e256d",
  "Worker API": "baf51b8e256d",
  "Web Components": "baf51b8e256d",
  WASM: "baf51b8e256d",
  Store: "baf51b8e256d",

  "Version Control": "baf51b8e256d",
  "Package Managers": "baf51b8e256d",
  "Task Runners": "baf51b8e256d",
  "CSS Preprocessors": "baf51b8e256d",
  "Module Bundlers": "baf51b8e256d",
  "Linter & Formatters": "baf51b8e256d",
  "TypeCheckers & Annotations": "baf51b8e256d",
  Testing: "baf51b8e256d",
  "Code Editors": "baf51b8e256d",
  "Network/DNS": "baf51b8e256d",
  "Backend API": "baf51b8e256d",
  "Backend Frameworks": "baf51b8e256d",
  "Cloud Providers": "baf51b8e256d",

  DOM: "ca60938450d0",
  "Virtual DOM": "ca60938450d0",
  "Shadow DOM": "ca60938450d0",

  Standarts: "7f8bdd7918eb",
  "UI Mechanics": "7f8bdd7918eb",
  "Design Systems": "7f8bdd7918eb",
  "Design Patterns": "7f8bdd7918eb",

  "FE Eğitim Seçimi": "ea45ac82faf9",

  Hooks: "ba562c99c305",
  WASM2: "ba562c99c305",
  GraphQL: "ba562c99c305",
  WebRTC: "ba562c99c305",
  MicroFrontends: "ba562c99c305",
  Serverless: "ba562c99c305",
  Typescript: "ba562c99c305",
  "Functional Programming": "ba562c99c305",
  "LowCode, NoCode": "ba562c99c305",
  "Reactive Programming": "ba562c99c305",
  "JAMStack 2022": "f733914db9e",

  "ui-projects": "bf4b5836dc61",
  trendoceans: "bf4b5836dc61",
  "twit stats": "bf4b5836dc61",
  skykeeper: "bf4b5836dc61",
  insurance: "bf4b5836dc61",
  ibeacon: "bf4b5836dc61",
  efinansmobil: "bf4b5836dc61",
  gibmobil: "bf4b5836dc61",
  enver: "bf4b5836dc61",
  thundra: "bf4b5836dc61",

  "Quality Control": "18cd855d6f3a",
  Security: "18cd855d6f3a",
  Accessibility: "18cd855d6f3a",
  Usability: "18cd855d6f3a",
  Performance: "18cd855d6f3a",
  Functionality: "18cd855d6f3a",
  Maintainability: "18cd855d6f3a",
  Semantics: "18cd855d6f3a",
  Validation: "18cd855d6f3a",
  "Layout and Design Consistency": "18cd855d6f3a",
  Typography: "18cd855d6f3a",
  "Code Quality": "18cd855d6f3a",
  "Coding Standart Compliance": "18cd855d6f3a",

  "URL Design": "38ca14c97a48",
  Responsive: "38ca14c97a48",
  "Web Performance": "38ca14c97a48",
  Offline: "38ca14c97a48",
}

const storiesGroup = [
  {
    title: "Frontend Uzmanlığı",
    storyPart: [
      {
        title: "Nasıl Frontend Uzmanı Olabilirim-1",
        postId: "baf51b8e256d",
      },
      {
        title: "Nasıl Frontend Uzmanı Olabilirim-2",
        postId: "81e4d2a3d6c5",
      },
      {
        title: "Nasıl Frontend Uzmanı Olabilirim-3",
        postId: "39d8de7406b5",
      },
    ],
  },
  {
    title: "Mantıksal Model ve Kavramlar",
    storyPart: [
      {
        title: "Mantıksal Model ve Kavramlar-1",
        postId: "60ce2e95efdc",
      },
      {
        title: "Mantıksal Model ve Kavramlar-2",
        postId: "d342b0a966fc",
      },
    ],
  },
  {
    title: "Baskıyı Aşmak",
    storyPart: [
      {
        title: "Teknolojik Değişimin Baskısı",
        postId: "ca60938450d0",
      },
      {
        title: "Teknolojik Degişime Ayak Uydurmak-1",
        postId: "7f8bdd7918eb",
      },
      {
        title: "Teknolojik Degişime Ayak Uydurmak-2",
        postId: "44596d34af87",
      },
      {
        title: "Akıl, Robotlar ve Felsefe",
        postId: "606ed14c7c49",
      },
      {
        title: "Nesiller Arası Teknoloji Farkı",
        postId: "50331e1a022c",
      },
      {
        title: "Neden Öğrenemiyoruz",
        postId: "c27220e9e71c",
      },
      {
        title: "Düzenli Blog Paylaşımları",
        postId: "10cd187aa314",
      },
    ],
  },
  {
    title: "Deneyimler",
    storyPart: [
      {
        title: "Geçmiş UI Uygulamaları",
        postId: "bf4b5836dc61",
      },
      {
        title: "Frontend Eğitimleri",
        postId: "ea45ac82faf9",
      },
    ],
  },
  {
    title: "Kalite (Quality)",
    storyPart: [
      {
        title: "Web Sayfası Kalite Kontrol",
        postId: "18cd855d6f3a",
      },
      {
        title: "Web Apps That Work Everywhere",
        postId: "38ca14c97a48",
      },
      {
        title: "Legacy Frontend Projeleri Birleşimleri",
        postId: "dc56518403e0",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Frontend Düşüncüleri",
  path: "frontend-ideas",
}

const FrontendDevIdeasPage = () => {
  return (
    <StoryGroup
      complex={true}
      title={context.title}
      stories={storiesGroup}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default FrontendDevIdeasPage
